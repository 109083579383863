<template>
  <v-container>
    <div class="d-flex justify-space-between align-center border-bottom">
      <div class="text-h6">Webbar</div>
      <v-spacer></v-spacer>
      <div>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="secondary"
              small
              text
              class="mt-3 mb-2 ml-2"
              @click="restartApache()"
            >
              <v-icon left>mdi-restart</v-icon>
              Apache
            </v-btn>
          </template>
          <span>Starta om Apache</span>
        </v-tooltip>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="secondary"
              small
              text
              class="mt-3 mb-2 ml-2"
              @click="restartNginx()"
            >
              <v-icon left>mdi-restart</v-icon>
              Nginx
            </v-btn>
          </template>
          <span>Starta om Nginx</span>
        </v-tooltip>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="secondary"
              small
              text
              class="mt-3 mb-2 ml-2"
              @click="editNginxConfig()"
            >
              <v-icon left>mdi-square-edit-outline</v-icon>
              Config
            </v-btn>
          </template>
          <span>Nginx config</span>
        </v-tooltip>
      </div>
    </div>
    <v-data-table
      :headers="hosts.length ? headers : []"
      :items="hosts"
      item-key="row"
      :search="search"
      :loading="!viewDataIsLoaded"
      loading-text="Hämtar data..."
      no-data-text="Inga webbar hittades"
      @click:row="editItem"
      class="v-data-table-clickable"
      hide-default-footer
      :items-per-page="hosts.length"
      :item-class="getItemClass"
    >
      <template v-slot:top v-if="hosts.length">
        <v-text-field
          v-model="search"
          label="Sök"
          single-line
          hide-details
          append-icon="mdi-magnify"
        ></v-text-field>
      </template>
      <!--
      <template v-slot:[`item.enabled`]="{ item }">
        <v-icon
          v-if="item.enabled"
          class="enabled"
        >
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon
          v-else
          class="disabled"
        >
          mdi-cancel
        </v-icon>
      </template>
      -->
    </v-data-table>
    <!--singleDialog-->
    <v-dialog
      v-model="singleDialog"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-toolbar
          dark
          color="secondary"
          dense
        >
          <v-btn
            icon
            dark
            @click="singleDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ singleHeadline }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="saveItem()"
            >
              <v-icon
                left
                dark
              >
                mdi-checkbox-marked-circle-outline
              </v-icon>
              Spara
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-progress-linear
          v-if="singleLoading"
          indeterminate
        ></v-progress-linear>
        <!--
        <v-card-title v-if="singleHost.isNew">
          <span class="text-h5">{{ singleHeadline }}</span>
        </v-card-title>
        -->
        <v-card-text v-if="!singleLoading">
          <v-container class="pt-8">
            <v-form ref="formEdit">
              <v-switch
                v-model="singleHost.enabled"
                inset
                label="Aktiv"
              ></v-switch>
              <v-text-field
                v-if="singleHost.isNew"
                v-model="singleHost.serverName"
                outlined
                :rules="singleHost.isNew ? [formRules.required, formRules.domain] : []"
                style="text-transform:lowercase !important;"
                label="Domännamn"
                :hide-details="!singleHost.isNew"
              >
              </v-text-field>
              <v-text-field
                v-if="singleHost.isNew"
                v-model="singleHost.alias"
                outlined
                label="Alias"
              >
              </v-text-field>
              <v-select
                v-if="singleHost.isNew"
                v-model="hostPreset"
                :items="hostPresets"
                outlined
                label="Typ"
                return-object
              ></v-select>
              <v-text-field
                v-model="singleHost.webroot"
                v-if="showField.webroot"
                outlined
                :rules="showField.webroot ? [formRules.required] : []"
                label="Webroot"
              >
              </v-text-field>
              <v-text-field
                v-model="singleHost.port"
                v-if="showField.port"
                outlined
                :rules="showField.port ? [formRules.required] : []"
                label="Port"
              >
              </v-text-field>
              <v-switch
                v-model="singleHost.kobraRewriteRules"
                v-if="showField.kobraRewriteRules"
                inset
                label="Sökmotorvänliga URLs"
              ></v-switch>
              <v-textarea
                v-model="singleHost.nginxConfig"
                v-if="!singleHost.isNew"
                label="Nginx config"
                :auto-grow="false"
                rows="16"
                outlined
                class="code"
                spellcheck="false"
                :rules="[formRules.required]"
              ></v-textarea>
              <v-textarea
                v-model="singleHost.apacheConfig"
                v-if="!singleHost.isNew"
                label="Apache config"
                :auto-grow="false"
                rows="8"
                outlined
                class="code"
                spellcheck="false"
              ></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-8"
        >
          <v-btn
            v-if="!singleHost.isNew"
            color="bad"
            text
            @click="confirmDelete = true"
          >
            Ta bort webben
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay
        absolute
        :value="singleSaveInProgress"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <!--nginxConfigDialog-->
    <v-dialog
      v-model="nginxConfigDialog"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-toolbar
          dark
          color="secondary"
          dense
        >
          <v-btn
            icon
            dark
            @click="nginxConfigDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nginx config</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="saveNginxConfig()"
            >
              <v-icon
                left
                dark
              >
                mdi-checkbox-marked-circle-outline
              </v-icon>
              Spara
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-progress-linear
          v-if="nginxConfigLoading"
          indeterminate
        ></v-progress-linear>
        <v-card-text v-if="!nginxConfigLoading">
          <v-container class="pt-8">
            <v-form ref="formEdit">
              <v-textarea
                v-model="nginxConfig"
                label="Nginx config"
                :auto-grow="false"
                rows="16"
                outlined
                class="code"
                spellcheck="false"
                :rules="[formRules.required]"
              ></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-overlay
        absolute
        :value="nginxConfigSaveInProgress"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-bottom-sheet v-model="confirmDelete">
      <v-sheet
        class="text-center"
        height="160px"
      >
        <div class="py-4">
          Domänen kommer nu tas bort från Nginx
        </div>
        <v-btn
          color="bad"
          dark
          @click="removeItem()"
        >
          Ta bort
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { appBus } from '../main'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'

export default {
  name: 'Nginx',
  mixins: [generalMixin, urlMixin],
  computed: {
    ...mapGetters(['access_token']),
  },
  props: {
    act: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      viewDataIsLoaded: false,
      hosts: [],
      headers: [],
      hostPresets: [],
      hostPreset: null,
      search: '',
      singleDialog: false,
      singleHeadline: '',
      singleLoading: false,
      confirmDelete: false,
      singleSaveInProgress: false,
      showField: {
        webroot: null,
        kobraRewriteRules: null,
        port: null
      },
      singleHost: {
        isNew: false,
        enabled: false,
        nginxConfig: '',
        apacheConfig: '',
        serverName: ''
      },
      singleHost_new: {
        isNew: true,
        enabled: true,
        nginxConfig: '',
        serverName: '',
        webroot: '',
        kobraRewriteRules: false,
        alias: '',
        port: ''
      },
      layoutData: {
        headline: 'Nginx',
        buttons: [
          {
            act: 'add',
            icon: 'mdi-plus'
          }
        ]
      },
      nginxConfig: '',
      nginxConfigLoading: false,
      nginxConfigDialog: false,
      nginxConfigSaveInProgress: false,
    }
  },
  watch: {
    act(value) {
      console.log('you clicked act', value)
      switch (value) {
        case 'add':
          this.editItem()
          break
      }
      this.$emit('actConsidered')
    },
    hostPreset(value) {
      this.showField.webroot = value && value.options.webroot
      this.showField.port = value && value.options.port
      this.showField.kobraRewriteRules = value && value.options.kobraRewriteRules
    }
  },
  methods: {
    getItemClass(item) {
      return item.enabled ? 'enabled' : 'disabled'
    },
    editItem(host) {
      this.hostPreset = null
      if (!host) {
        //new
        this.singleHeadline = 'Ny webb'
        this.singleHost = Object.assign({}, this.singleHost_new)
        this.singleDialog = true
        return
      }
      //edit existing
      this.singleHost = host
      this.singleLoading = true
      this.singleHeadline = host.serverName
      this.singleDialog = true
      //get item data
      let config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      let url = this.url_composeApiUrl('/core/nginx/host/' + host.file)
      console.log('get', url, config)
      axios.get(url, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success
            console.log('res.data', res.data)
            this.singleHost = Object.assign({}, res.data.host)
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
          this.singleLoading = false
        })
        .catch(error => {
          this.displayAxiosCatchError(error)
          this.singleLoading = false
        })
    },
    editNginxConfig() {
      //edit existing
      this.nginxConfigLoading = true
      this.nginxConfigDialog = true
      //get item data
      let config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      let url = this.url_composeApiUrl('/core/nginx/config')
      console.log('get', url, config)
      axios.get(url, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success
            console.log('res.data', res.data)
            this.nginxConfig = res.data.nginxConfig
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
          this.nginxConfigLoading = false
        })
        .catch(error => {
          this.displayAxiosCatchError(error)
          this.nginxConfigLoading = false
        })
    },
    saveItem() {
      const formIsValid = this.$refs.formEdit.validate()
      if (!formIsValid) {
        appBus.$emit('message', 'Formuläret ej komplett ifyllt')
        return
      }
      this.singleSaveInProgress = true
      //post data to API
      const config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      const postData = {
        host: this.singleHost
      }
      if (this.singleHost.isNew) {
        postData.hostPreset = this.hostPreset
      }
      let endPoint = '/core/nginx/host'
      if (!this.singleHost.isNew) {
        //append file in order to know which host to save
        endPoint += '/' + this.singleHost.file
      }
      const url = this.url_composeApiUrl(endPoint)
      axios.post(url, postData, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success
            this.doOnViewData(res.data);
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
          this.singleSaveInProgress = false
          this.singleDialog = false
        })
        .catch(error => {
          this.singleSaveInProgress = false
          this.displayAxiosCatchError(error)
        })
    },
    saveNginxConfig() {
      const formIsValid = this.$refs.formEdit.validate()
      if (!formIsValid) {
        appBus.$emit('message', 'Formuläret ej komplett ifyllt')
        return
      }
      this.nginxConfigSaveInProgress = true
      //post data to API
      const config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      const postData = {
        nginxConfig: this.nginxConfig
      }
      let endPoint = '/core/nginx/config'
      const url = this.url_composeApiUrl(endPoint)
      axios.post(url, postData, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success - do nothing
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
          this.nginxConfigSaveInProgress = false
          this.nginxConfigDialog = false
        })
        .catch(error => {
          this.nginxConfigSaveInProgress = false
          this.displayAxiosCatchError(error)
        })
    },
    removeItem() {
      this.confirmDelete = false
      this.singleSaveInProgress = true
      //post data to API
      const config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      let endPoint = '/core/nginx/host/' + this.singleHost.file
      const url = this.url_composeApiUrl(endPoint)
      axios.delete(url, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success
            this.doOnViewData(res.data);
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
          this.singleSaveInProgress = false
          this.singleDialog = false
        })
        .catch(error => {
          this.singleSaveInProgress = false
          this.displayAxiosCatchError(error)
        })
    },
    loadViewData() {
      //get view data
      const config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      const url = this.url_composeApiUrl('/core/nginx/host')
      console.log('get', url, config)
      axios.get(url, config)
        .then(res => {
          console.log('response', res)
          if (res.status == 200) {
            //success
            this.doOnViewData(res.data);
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
        })
        .catch(error => {
          this.displayAxiosCatchError(error)
        })
    },
    restartNginx() {
      appBus.$emit('overlay', { message: process.env.NODE_ENV == 'development' ? 'Låtsas starta om Nginx' : 'Startar om Nginx' })
      //get view data
      const config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      const url = this.url_composeApiUrl('/core/nginx/restart')
      console.log('get', url, config)
      axios.get(url, config)
        .then(res => {
          appBus.$emit('overlay')
          console.log('response', res)
          if (res.status == 200) {
            //success
            console.log(res.data);
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
        })
        .catch(error => {
          appBus.$emit('overlay')
          this.displayAxiosCatchError(error)
        })
    },
    restartApache() {
      appBus.$emit('overlay', { message: process.env.NODE_ENV == 'development' ? 'Låtsas starta om Apache' : 'Startar om Apache' })
      //get view data
      const config = {
        headers: {
          'Authorization': 'Bearer ' + this.access_token
        }
      }
      const url = this.url_composeApiUrl('/core/nginx/restart-apache')
      console.log('get', url, config)
      axios.get(url, config)
        .then(res => {
          appBus.$emit('overlay')
          console.log('response', res)
          if (res.status == 200) {
            //success
            console.log(res.data);
          } else if (this.onNonSuccessApiCall(res)) {
            appBus.$emit('message', this.composeErrorMessage(res, 'Okänt fel (43)'))
          }
        })
        .catch(error => {
          appBus.$emit('overlay')
          this.displayAxiosCatchError(error)
        })
    },
    doOnViewData(data) {
      if (data.headers) {
        this.headers = data.headers
      }
      if (data.hosts) {
        this.hosts = data.hosts
      }
      if (data.hostPresets) {
        this.hostPresets = data.hostPresets
      }
      this.viewDataIsLoaded = true
    },
  },
  created() {
    this.$emit('layoutData', this.layoutData)
    this.loadViewData()
  }
}
</script>

<style>
  .disabled {
    color:#CCC !important;
  }
</style>